<template>
  <div class="patient-list">
    <div class="searchBox">
      <search-comp size="mini" :search-opts="searchOpts" :search-data="searchData" label-width="60px" searchLabel="查询"></search-comp>
    </div>
    <table-comp :table-opts="tableOpts" :data="tableData"  :open-handle="true" @other_1="handleOther_1"></table-comp>
  </div>
</template>

<script>
import SearchComp from '@/components/Search/Search'
import TableComp from '@/components/Table/Table'
import { patientList } from '@/service/module/patient'

export default {
  components: {
    SearchComp,
    TableComp
  },
  data () {
    return {
      searchOpts: [
        {
          label: '姓名',
          key: 'name',
          placeholder: '请输入姓名',
          type: 'input'
        },
        {
          label: '手机号',
          key: 'mobile',
          placeholder: '请输入手机号',
          type: 'input'
        }
      ],
      searchData: {
        name: '',
        mobile: ''
      },
      tableOpts: [
        {
          label: '姓名',
          prop: 'name',
          width: 80
        },
        {
          label: '微信昵称',
          prop: 'nickname'
        },
        {
          label: '性别',
          prop: 'gender',
          width: 60,
          render: (h, params) => {
            const gender = params.row.gender
            let genderText = ''
            switch (gender) {
              case 0 : genderText = '女'
                break
              case 1 : genderText = '男'
                break
            }
            return h('span', {}, genderText)
          }
        },
        {
          label: '年龄',
          prop: 'age',
          width: 60,
          render: (h, params) => {
            const age = params.row.age
            const ageText = '-'
            if (age === null) {
              return h('span', {}, ageText)
            }
          }
        },
        {
          label: '手机号',
          prop: 'mobile',
          width: 120
        },
        {
          label: '身份证号',
          prop: 'identityCard'
        },
        {
          label: '注册时间',
          prop: 'registerTime'
        },
        {
          label: '邀请人',
          prop: 'inviter'
        }
      ],
      tableData: [],
      handleOpts: {
        other_1: {
          type: 'text',
          text: '患者档案'
        }
      }
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      const [err, res] = await this.$to(patientList())
      console.log('data:', res)
      this.tableData = res.data
      if (err) {
        throw new Error(err)
      }
    },
    handleOther_1 () {
    }
  }
}
</script>

<style scoped lang="scss">
 .searchBox {
   margin-bottom: 20px;
 }
</style>
